import React, { useRef } from "react";
import useOnScreen from '@lmig/dotcom-aspect-hooks/useOnScreen';

export default function LazyLoad({ children, offset = '25%' }) {
  const loaderRef = useRef();
  const shouldBeLoaded = useOnScreen(loaderRef, { rootMargin: offset });

  return (
    <div ref={loaderRef}>
      {shouldBeLoaded && children}
    </div>
  );
}
