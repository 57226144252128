import { useEffect, useState } from 'react';
import useMeasure from '@lmig/dotcom-aspect-hooks/useMeasure';

const useWindowWidth = (options = {}) => {
  const { mobileWidth = 600 } = options;
  const [{ ref }, { width: windowWidth }] = useMeasure();
  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    ref.current = document.body;
  }, [ref]);

  useEffect(() => {
    if (windowWidth > 0) {
      setIsMobile(windowWidth < mobileWidth && windowWidth > 0);
    }
  }, [windowWidth, mobileWidth]);

  return { isMobile, windowWidth };
};

export default useWindowWidth;