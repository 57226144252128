import React from 'react';
import { oneOf, PropTypes } from 'prop-types';
import css from 'styled-jsx/css';
import cx from 'classnames';
import { A11yOnly } from '@lmig/lmds-react';
import StarHalfFilled from './Icon/IconStarHalfFilled';
import StarFilled from './Icon/IconStarFilled';
import Star from './Icon/IconStar';

const getStarStyles = () => css.resolve`
    * {
      height: 100%;
      display: inline-block;
      margin: 0 0.125rem;
    }
  `;

const ratingValue = (props, propName, componentName) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props[propName]) {
    const value = props[propName];

    if (typeof value === 'number') {
      return value >= 0.5 && value <= 5
        ? null
        : new Error(`${propName} in ${componentName} is not between 1 and 5`);
    }
  }

  return null;
};

// eslint-disable-next-line complexity
const RatingStars = ({
  rating = 3,
  size = 32,
  className,
  allyOn = true,
  starType = 'classic'
}) => {
  const { className: starClass, styles: starStyles } = getStarStyles();
  const stars = [];

  for (let i = 1; i <= 5; i += 1) {
    if (i <= Math.floor(rating) && i <= rating) {
      stars.push(
        <span className={starClass} key={`star-${i}`}>
          {starType === "classic" && <StarFilled size={`${size}px`} />}
          {starType === "lmds" && <svg className="lm-Icon lm-Icon-RatingsStarFilled lm-Icon--color-blue" style={{ width: "1rem" }}><use href="#lmds-filled" /></svg>}
        </span>,
      );
    }

    if (i > Math.floor(rating) && i === Math.ceil(rating)) {
      stars.push(
        <span className={starClass} key={`star-${i}`}>
          {starType === "classic" && <StarHalfFilled size={`${size}px`} />}
          {starType === "lmds" && <svg className="lm-Icon lm-Icon-RatingsStarHalf lm-Icon--color-blue" style={{ width: "1rem" }}><use href="#lmds-half-filled" /></svg>}
        </span>,
      );
    }

    if (i > Math.ceil(rating)) {
      stars.push(
        <span className={starClass} key={`star-${i}`}>
          {starType === "classic" && <Star size={`${size}px`} />}
          {starType === "lmds" && <svg className="lm-Icon lm-Icon-RatingsStarUnfilled lm-Icon--color-blue" style={{ width: "1rem" }}><use href="#lmds-not-filled" /></svg>}
        </span>,
      );
    }
  }

  const sizeClassName = {
    '32': 'ratingStars-large',
    '24': 'ratingStars-medium',
    '16': 'ratingStars-small',
  }[size];

  return (
    <div className={className}>
      {allyOn ? (
        <A11yOnly>{`${rating} out of 5 stars`}</A11yOnly>
      ) : (
        <div>{`${rating} out of 5 stars`}</div>
      )}
      <span className={cx('ratingStars', sizeClassName)}>{stars}</span>
      <style jsx>{`
        .ratingStars {
          display: inline-block;
        }
        .ratingStars-large {
          height: 2rem;
        }
        .ratingStars-medium {
          height: 1.5rem;
        }
        .ratingStars-small {
          height: 1rem;
        }
      `}</style>
      {starStyles}
    </div>
  );
};

RatingStars.propTypes = {
  /**
    The rating value
  */
  rating: ratingValue,
  /**
   * The size of the stars
   */
  size: oneOf([16, 24, 32]),
  /**
  * Classname for styling
  */
  className: PropTypes.string,
  /**
  * Toggle for ally
  */
  allyOn: PropTypes.bool,
  /**
  * Toggle for ally
  */
  starType: PropTypes.oneOf(["classic", "lmds"])
};

export default RatingStars;
